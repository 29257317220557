.App {
  font-family: sans-serif;
  text-align: center;
}
.pdf-css td{
    border-style: solid !important;
    border-width: 1pt !important;
    border-color: rgb(127, 127, 127) !important;
   
}
 .pdf-css table{
    width:100% !important;
}

